html {
  min-height: 100% !important;
  height: 100%;
  /* root font size */
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  background-color: rgb(250, 250, 250);
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.46429em;
  font-weight: 400;
  font-size: 0.875rem;
  margin: 0;
}
